
import Vue from 'vue';
import IBlogPost from '@localazy/components/dist/modules/ghost/models/blog-post';
import FeaturedBrandsContainer from '@/modules/featured-brands/containers/FeaturedBrandsContainer.vue';
import BlogCard from '@/modules/blog/components/BlogCard.vue';
import BlogService from '@/modules/blog/services/blog-service';
import ThreeColsCta from '@/modules/@common/components/ctas/ThreeColsCta.vue';
import Posts from '@/components/index/Posts.vue';
import HubspotContentType from '@localazy/core/dist/modules/analytics/enums/hubspot-content-type';
import CorporationLocalazy from '@/modules/@common/structured-data/corporation_localazy';
import VideoLocalazy from '@/modules/@common/structured-data/video_localazy';
import { Homepage } from '@localazy/directus-service/lib/@types';
import { HomepageService } from '@localazy/directus-service';
import TwoLineShowcase from '@/modules/@common/components/showcases/TwoLineShowcase.vue';
import IntegrationShowcase from '@/modules/@common/components/showcases/IntegrationShowcase.vue';
import DeveloperFriendly from '@/modules/index-page/components/DeveloperFriendly.vue';
import UptodateTranslations from '@/modules/index-page/components/UptodateTranslations.vue';
import BestFeedback from '@/modules/index-page/components/BestFeedback.vue';
import TrustedSecure from '@/modules/index-page/components/TrustedSecure.vue';
import AdvancedFeaturesCarousel from '@/modules/@common/components/carousels/AdvancedFeaturesCarousel.vue';
import HeroSection from '@/modules/index-page/components/HeroSection.vue';
import CaseStudyCarousel from '@/modules/@common/components/carousels/CaseStudyCarousel.vue';

const AutopilotFeatured = () =>
  import('@/modules/@common/components/featured/AutopilotFeatured.vue');
const metaTitle = 'Software localization on autopilot';
const metaDescription =
  'Made for developers and easy for anybody else, Localazy is number 1 choice for digital product teams worldwide.';

interface IData {
  posts: IBlogPost[];
  homepage: Homepage | null,
}

export default Vue.extend({
  components: {
    FeaturedBrandsContainer,
    BlogCard,
    Posts,
    ThreeColsCta,
    AutopilotFeatured,
    TwoLineShowcase,
    IntegrationShowcase,
    DeveloperFriendly,
    UptodateTranslations,
    BestFeedback,
    TrustedSecure,
    AdvancedFeaturesCarousel,
    HeroSection,
    CaseStudyCarousel
  },
  meta: {
    setContentType: HubspotContentType.LANDING_PAGE
  },
  async asyncData(): Promise<Partial<IData>> {
    const [posts, homepage] = await Promise.all([
      BlogService.fetchHighlightBlogPosts({ limit: 3 }),
      HomepageService.fetchHomepage()
    ]);

    return {
      posts,
      homepage
    };
  },
  data(): IData {
    return {
      posts: [],
      homepage: null
    };
  },
  head(): any {
    return {
      title: metaTitle,
      meta: [
        { hid: 'description', name: 'description', content: metaDescription },
        { hid: 'og:title', property: 'og:title', content: metaTitle },
        {
          hid: 'og:description',
          property: 'og:description',
          content: metaDescription
        },
        { hid: 'twitter:title', property: 'twitter:title', content: metaTitle },
        {
          hid: 'twitter:description',
          property: 'twitter:description',
          content: metaDescription
        }
      ],
      script: [
        {
          type: 'application/ld+json',
          json: this.VideoStructuredDataContent
        },
        {
          type: 'application/ld+json',
          json: this.CorporationStructuredDataContent
        }
      ]
    };
  },
  computed: {
    CorporationStructuredDataContent(): Object {
      return {
        '@context': 'https://schema.org',
        ...CorporationLocalazy()
      };
    },
    VideoStructuredDataContent(): Object {
      return {
        '@context': 'https://schema.org',
        ...VideoLocalazy()
      };
    }
  }
});
